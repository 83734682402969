@import "../../global-style.scss";

// Hack for empty columns 
[class*="col-"] {
    display: inline-block;
    margin-right: -0.2em; /* small hack to fix inline block spacing */
}

// enlarging the spinner
.spinner-border {
    width: 4rem;
    height: 4rem;
}

// override standard spinner size
.loading-spinner {
    width: 95%;
    height: 95%;
    padding-top: 15px;
}

.spinner-style {
    border: 3px solid #00FFB9; 
    height: 16rem; 
    padding: 6px; 
    border-radius: 10px;
}