/* GLOBAL STYLES */

main {
  padding-top: 68px;
}

a {
  text-decoration: underline;
}

li a {
  text-decoration: none;
}

.bg-vrt-line {
  left: 36%;
  background-color: #ffffff;
  width: 5px;
  height: 200px;
  position: relative;
  z-index: 1;
  top: 80px;
}

// override default styles in main component
.nav-tabs .nav-link {
  padding: 17px 30px;
}

.red {
  color: #fe8389;
}

.icon-box {
  border: 3px solid #00ffb9;
  border-radius: 7px;
  width: 270px;
  min-height: 155px;
  text-align: center;
  margin: 35px 0;
  z-index: 2;
  position: absolute;
  background-color: #000028;
  i {
    font-size: 40px;
    margin-top: 35px;
    color: #00ffb9;
  }
  div {
    margin-top: 10px;
    color: #00ffb9;
    font-size: smaller;
  }
}

.inactive {
  border-color: dimgrey !important;
  i {
    border-color: dimgrey !important;
    color: dimgrey !important;
  }
  div {
    color: dimgrey !important;
  }
}

// Hide the scrollbar
section::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

// style for text-emphasis
.emphasis {
  color: #00ffb9;
  font-weight: 900;
}

button {
  font-weight: 900;
}

.fakeLink {
  color: #00ffb9;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    font-weight: 900;
    text-decoration: none;
    &::after {
      content: " →";
    }
  }
  
}
